<template>
  <div class="user-chat" ref="list">
    <div class="main-wrap">
      <div class="title-wrap">
        <div class="title">{{ friendInfo.fname }}</div>
      </div>
      <!-- s聊天区域 -->
      <div class="chat-list" v-infinite-scroll="getData">
        <div class="data-item" v-for="item in chatlist" :key="item.id">
          <div class="date">{{ item.time }}</div>
          <div v-if="item.tip == '0'" class="msg">
            <div class="user-img">
              <img :src="item.userimg" alt="" />
            </div>
            <div v-show="item.types" class="chat-data">
              {{ item.message }}
            </div>
          </div>
          <div v-else class="msg msg-right">
            <div v-show="item.types" class="chat-data">
              {{ item.message }}
            </div>
            <div class="user-img">
              <img :src="item.userimg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <!-- 输入区域 -->
      <div class="submit-wrap">
        <div class="use-func">
          <div class="func-item">
            <i class="iconfont icon-biaoqing"></i>
          </div>
          <div class="func-item" @click="test">
            <i class="iconfont icon-tupian"></i>
          </div>
          <div class="func-item">
            <i class="iconfont icon-position"></i>
          </div>
          <div
            :class="allSend ? 'submit-but active_submit' : 'submit-but'"
            @click="sendData"
          >
            发送
          </div>
        </div>
        <div class="input-wrap">
          <textarea v-model="formData" @input="checkInput"></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myStore from ".././../../utils/localStore";
import formatDate from "../../../utils/formatDate";

export default {
  name: "userChat",
  props: ["friendId"],
  data() {
    return {
      userinfo: myStore.getStore("userinfo"),
      query: {
        nowpage: 1,
        maxpage: 0,
        total: 1,
      },
      chatlist: [], // 聊天数据
      friendInfo: {}, // 朋友的信息
      formData: "", // 文本信息
      allSend: false, // 允许发送
    };
  },
  beforeMount() {
    this.mountSocket();
    this.getChatData();
    // 挂载页面滚动事件
    window.addEventListener("scroll", this.myScrool);
  },
  mounted() {},
  beforeDestroy() {
    // 卸载窗口滚动事件
    window.removeEventListener("scroll", this.myScrool);
  },
  methods: {
    // 挂载socket服务
    mountSocket() {
      this.socket.on("msgs", (msg, fid) => {
        // 类型校验

        // 页面渲染
        this.chatlist.push(msg);
      });
    },

    test() {
      this.$notify({
        // title: "HTML 片段",
        dangerouslyUseHTMLString: true,
        message: `<div style='color:red''>这是 <i>HTML</i> 片段</div>`,
        duration: 600000, // d显示时间
      });
    },

    // 获取聊天数据
    async getChatData() {
      const { data: res } = await this.$http.get(
        `chat_data/?usera=${this.userinfo.id}&userb=${this.friendId}&nowpage=${this.query.nowpage}&maxpage=${this.query.maxpage}`
      );

      if (res.code !== "200") return;
      this.chatlist = res.data;
      this.friendInfo = res.friend;
      // 格式化
      this.chatlist.forEach((v) => {
        v.time = formatDate.format1(v.time);
      });
    },

    // 窗口混动
    myScrool() {
      const list = this.$refs.list;
      console.log(list);
    },

    // 上滑刷新
    getData() {},

    // 发送数据
    sendData() {
      if (this.allSend) {
        let form = {
          usera: this.userinfo.id,
          userb: this.friendId,
          message: this.formData,
          tip: "1",
          types: 1,
        };
        // 发送至服务器
        this.sendDataToServer(form);
        // 发送给soclet
        this.sendForSocet(form);
      }
    },

    // 发送消息到服务器
    async sendDataToServer(form) {
      let { data: res } = await this.$http.post("chat_data/", form);
      if (res.code !== "200") {
        // 发送失败
        return;
      }
      
      // 发送成功 本地渲染
      this.chatlist = [...this.chatlist, res.data];
    },

    // 发送给soclet
    sendForSocet(data) {
      data["tip"] = "0";
      data["userimg"] = this.userinfo.userimg;
      this.socket.emit("msg", data, this.userinfo.id, parseInt(this.friendId));
    },

    // 将要上传图片
    beforeAvatarUpload() {},
    // 上传图片成功
    handleAvatarSuccess() {},

    // 监听输入框
    checkInput() {
      this.formData.length !== 0
        ? (this.allSend = true)
        : (this.allSend = false);
    },
  },
};
</script>

<style lang='less' scoped>
.el-notification__content {
  p {
    div {
      color: rgb(214, 22, 22) !important;
      background: black;
    }
  }
}

.user-chat {
  padding-right: 10px;
  width: 100%;

  .main-wrap {
    width: 100%;

    .title-wrap {
      position: fixed;
      top: 30px;
      height: 32px;
      width: 710px;
      background: #f6f5f8;
      border-bottom: solid 1px rgb(216, 216, 216);
      .title {
        text-align: center;
        line-height: 32px;
      }
    }

    .chat-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-top: 40px;
      padding-bottom: 170px;

      .data-item {
        margin: 15px 0;
        .date {
          height: 20px;
          line-height: 20px;
          font-size: 12px;
          text-align: center;
          color: rgb(121, 121, 121);
        }

        .msg {
          display: flex;
          .user-img {
            padding: 5px 15px;
            img {
              height: 40px;
              width: 40px;
              border-radius: 50%;
            }
          }

          .chat-data {
            max-width: 400px;
            padding: 5px 10px;
            margin-top: 20px;
            background: #fff;
          }
        }

        .msg-right {
          justify-content: flex-end;

          .chat-data {
            background: #98e165;
          }
        }
      }
    }

    .submit-wrap {
      position: fixed;
      bottom: 28px;
      height: 160px;
      z-index: 101;
      width: 710px;
      background: #f6f5f8;
      border-top: solid 1px rgb(216, 216, 216);

      .use-func {
        height: 60px;

        .func-item {
          float: left;
          width: 60px;
          height: 100%;
          padding: 5px;
          text-align: center;
          line-height: 50px;
          color: rgb(160, 160, 160);
          i {
            font-size: 30px !important;
          }
        }
        .func-item:hover {
          color: #3d5aeb;
        }

        // 发送按钮
        .submit-but {
          float: right;
          margin-top: 10px;
          margin-right: 10px;
          width: 60px;
          height: 30px;
          text-align: center;
          line-height: 30px;
          color: #fff;
          background: #a0a0a0;
          border-radius: 10px;
          box-shadow: 1px 1px 1px rgb(133, 133, 133);
          cursor: pointer; // 显示手指
        }
        .active_submit {
          background: #3d59ef;
        }
      }

      .input-wrap {
        height: 80px;
        width: 100%;
        color: black !important;
        textarea {
          width: 100%;
          height: 100%;
          font-size: 15px;
          line-height: 16px;
          padding: 5px 15px;
          color: black !important;
          background: #f6f5f8;
          border: none;
          outline: none;
        }
      }
    }
  }
}
</style>