// 标准化时间
const dateformat = (fmt, date) => {
    let ret;
    const opt = {
        "Y+": date.getFullYear().toString(),        // 年
        "m+": (date.getMonth() + 1).toString(),     // 月
        "d+": date.getDate().toString(),            // 日
        "H+": date.getHours().toString(),           // 时
        "M+": date.getMinutes().toString(),         // 分
        "S+": date.getSeconds().toString()          // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        };
    };
    return fmt;
}


export default {
	// 时间戳 -> 视频时长
	format5:(time_num)=>{
		let time = new Date(time_num)
		time = dateformat('00:MM:SS', time)
		return time
	},


	//  传入两个连续的
	format4:(array)=>{
		for(let i=0;i<array.length;i++){
			if(i==array.length){
				array[i]=format3(array[i])
			}else{
				if(new Date(array[i])-new Date(array[i+1])==5*60*1000){
					array[i]='刚刚'
				}else{
					array[i]=format3(array[i])
				}
			}
		}
	},


	// 获取年龄
	format3(str){
		let date = new Date(str)
		let now = new Date()
        let age = new Date(parseInt(now - date)).getFullYear() - 1970
        return age 
	},

    // datestr
	format2 : (date)=>{
		date = new Date(date)
		date = dateformat('Y年m月d日',date)
		return  date
	},
	
	// 传入是一个datesString
	format1 : (date)=>{
		date = new Date(date)
		
		if(Date.now() - date.getTime() <= 5*60*1000){
			// 五分钟内
			date = '刚刚'
		}
		else if(new Date().getDate()==date.getDate()){
			if(date.getHours()>=8&&date.getHours()<12){
				date = dateformat('早上H:M', date)
			}
			else if(date.getHours()>=12&&date.getHours()<18){
				date = dateformat('下午H:M', date)
			}
			else{
				date = dateformat('晚上H:M', date)
			}
		}
		else if(new Date().getDate()-1 == date.getDate()){
			date = dateformat('昨天H:M', date)
		}
		else if(new Date().getFullYear() == date.getFullYear()){
			date = dateformat('m月d日', date)
		}
		else{
			date = dateformat('YYYY年mm月dd日', date)
		}
		
		return date
	}
}
